var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_result_customer_maintenance") } },
    [
      _c(
        "a-form",
        {
          attrs: {
            form: _vm.form,
            "wrapper-col": { span: 14 },
            "label-col": { span: 8 }
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitForm(true)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "left",
                        label: _vm.$t(
                          _vm.formRules.customerMaintenanceNumber.label
                        )
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value:
                                _vm.formRules.customerMaintenanceNumber
                                  .decorator,
                              expression:
                                "formRules.customerMaintenanceNumber.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.customerMaintenanceNumber.name,
                            placeholder: _vm.$t(
                              _vm.formRules.customerMaintenanceNumber
                                .placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.loadingCustomerMaintenance,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListCustomerMaintenanceNumber(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataListCustomerMaintenance, function(data) {
                          return _c(
                            "a-select-option",
                            {
                              key: data.resultWorkOrderId,
                              attrs: { value: data.documentNumber }
                            },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " + _vm._s(data.documentNumber) + " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(data.documentNumber) + " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "left",
                        label: _vm.$t(_vm.formRules.workOrderNumber.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.workOrderNumber.decorator,
                              expression: "formRules.workOrderNumber.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.workOrderNumber.name,
                            placeholder: _vm.$t(
                              _vm.formRules.workOrderNumber.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.loadingWorkOrderNumber,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListWorkOrderNumber(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataWorkOrder, function(data, index) {
                          return _c(
                            "a-select-option",
                            {
                              key: index,
                              attrs: { value: data.documentNumber }
                            },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s("" + data.documentNumber) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s("" + data.documentNumber) + " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "left",
                        label: _vm.$t(_vm.formRules.mechanic.label)
                      }
                    },
                    [
                      _c("SelectMechanic", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.mechanic.decorator,
                            expression: "formRules.mechanic.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.mechanic.name,
                          "label-in-value": "",
                          placeholder: _vm.$t(
                            _vm.formRules.mechanic.placeholder
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        "label-align": "left",
                        label: _vm.$t(_vm.formRules.customerName.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.customerName.decorator,
                              expression: "formRules.customerName.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.customerName.name,
                            placeholder: _vm.$t(
                              _vm.formRules.customerName.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.loadingCustomerName,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListCustomer(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataCustomer, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.fullName } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          data.fullName.replace("null", "")
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        data.fullName.replace("null", "")
                                      ) +
                                      " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.branch.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.branch.decorator,
                              expression: "formRules.branch.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.branch.name,
                            placeholder: _vm.$t(
                              _vm.formRules.branch.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.loadingBranch,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getBranch(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataBranch, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.unitCode.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.unitCode.decorator,
                              expression: "formRules.unitCode.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.unitCode.name,
                            placeholder: _vm.$t(
                              _vm.formRules.unitCode.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.loadingUnitCode,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getUnitCode(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataUnitCode, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.unitCode } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.unitCode) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.unitCode) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.productCode.label) }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.productCode.decorator,
                              expression: "formRules.productCode.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.productCode.name,
                            placeholder: _vm.$t(
                              _vm.formRules.productCode.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.loadingPartNumber,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListPartNumber(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataPartNumber, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.productCode } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.productCode) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.productCode) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.dateFrom.label) } },
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.dateFrom.decorator,
                            expression: "formRules.dateFrom.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.dateFrom.name,
                          placeholder: _vm.$t(
                            _vm.formRules.dateFrom.placeholder
                          ),
                          format: _vm.DEFAULT_DATE_FORMAT
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.dateTo.label) } },
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.dateTo.decorator,
                            expression: "formRules.dateTo.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.formRules.dateTo.name,
                          placeholder: _vm.$t(_vm.formRules.dateTo.placeholder),
                          format: _vm.DEFAULT_DATE_FORMAT
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.finish.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.finish.decorator,
                              expression: "formRules.finish.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.finish.name,
                            placeholder: _vm.$t(
                              _vm.formRules.finish.placeholder
                            ),
                            "option-filter-prop": "children",
                            allowClear: true
                          }
                        },
                        _vm._l(_vm.dataFinish, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.key } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.value) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.value) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.clearItems } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.loadingFind,
                            "html-type": "submit"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.$can("create", "result-work-order")
                ? _c(
                    "a-col",
                    { attrs: { span: 12, align: "end" } },
                    [
                      _c(
                        "RouterLink",
                        {
                          attrs: {
                            to: { name: "logistic.work-order.result.create" }
                          }
                        },
                        [
                          _c("a-button", { attrs: { type: "primary" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("lbl_create_new")) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-table",
        {
          attrs: {
            "data-source": _vm.dataList,
            columns: _vm.columnsTable,
            loading: _vm.loading.table,
            size: "small",
            pagination: {
              showTotal: function() {
                return _vm.$t("lbl_total_items", { total: _vm.totalData })
              },
              total: _vm.totalData,
              showSizeChanger: true,
              current: _vm.page,
              pageSizeOptions: _vm.PAGE_SIZE_OPTIONS
            },
            scroll: { y: 700, x: "calc(100% + 1300px)" }
          },
          on: { change: _vm.onChangeTable },
          scopedSlots: _vm._u([
            {
              key: "currency",
              fn: function(text) {
                return _c("span", {}, [
                  _vm._v(_vm._s(_vm._f("currency")(text)))
                ])
              }
            },
            {
              key: "number",
              fn: function(text) {
                return _c("span", {}, [_vm._v(_vm._s(_vm._f("qty")(text)))])
              }
            },
            {
              key: "date",
              fn: function(text) {
                return _c("span", {}, [_vm._v(_vm._s(_vm._f("date")(text)))])
              }
            },
            {
              key: "nullable",
              fn: function(text) {
                return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
              }
            },
            {
              key: "operation",
              fn: function(text, record) {
                return [
                  _vm.$can("create", "result-work-order") &&
                  !record.resultWorkOrderId
                    ? _c(
                        "a",
                        {
                          attrs: { "data-testid": "rwo-list-create" },
                          on: {
                            click: function($event) {
                              return _vm.openForm(record)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_create")) + " ")]
                      )
                    : _c(
                        "a",
                        {
                          attrs: { "data-testid": "rwo-list-view" },
                          on: {
                            click: function($event) {
                              return _vm.openForm(record)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_view")) + " ")]
                      )
                ]
              }
            }
          ])
        },
        [
          _c(
            "a-descriptions",
            { attrs: { slot: "footer", size: "small" }, slot: "footer" },
            [
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_total_qty_wo") } },
                [
                  _vm._v(
                    " " + _vm._s(_vm._f("qty")(_vm.footer.totalQtyWo)) + " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_total_qty_result") } },
                [
                  _vm._v(
                    " " + _vm._s(_vm._f("qty")(_vm.footer.totalQtyResult)) + " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-right mt-2" },
        [
          _c(
            "a-space",
            [
              _c(
                "a-button",
                {
                  attrs: { loading: _vm.loading.download, type: "primary" },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              ),
              _c(
                "a-button",
                {
                  attrs: { loading: _vm.loading.print, type: "primary" },
                  on: { click: _vm.handlePrint }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }